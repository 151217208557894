import Prestalib from "../../prestalib/js/prestalib.js";

if(typeof Prestalib == 'undefined') throw 'Playermod: Prestalib is not enabled';

export default class PlayerMod extends Prestalib{
	// player;
	// audioPlayer;
	// config={};
	// endpoint='/module/playermod/ajax';

	constructor(){
		super();
		this.config={};
		this.player=null;
		this.audioPlayer=null;
		this.endpoint='/index.php?fc=module&module=playermod&controller=ajax'
		this.progressbar =document.getElementById("progress");
	
		this.verbose = (typeof APP_ENV !='undefined' && APP_ENV=='production') ? false: 4;

		// if(typeof superlayout !='undefined')
		// 	this.verbose=superlayout.verbose;
		this.init();
	}
 thumb_scroller(wrapper){
	if(!wrapper) return false;
	if(!wrapper.querySelector('.mediaelement')) return false;

	let thumb = wrapper.querySelector('.mejs-time-handle');
	let slider = wrapper.querySelector('.mejs-time-slider');
	let id = wrapper.querySelector('.mediaelement').id;
	if(typeof thumb == 'undefined'){
		this.warn('PM: not found '+wrapper.innerHTML);
		return;
	}

	slider.addEventListener('mousedown',onMouseDown);

	function onMouseDown(event){
		this.log('PM: clicked pos:' + event.clientX,8);
		let newLeft = event.clientX - slider.getBoundingClientRect().left;
		this.log('PM: new left : '  + newLeft,8);
		thumb.style.left=newLeft+'px';

		if(audioPlayer.duration  && audioPlayer.getAttribute('data-id') == id  ){
			let newTime = (newLeft +1 )/slider.clientWidth * audioPlayer.duration;
			if(newTime)
				audioPlayer.currentTime = newTime;
				monitorProgress();
		}
		// let = audioPlayer.currentTime / audioPlayer.duration
	}

	thumb.onmousedown = function(event) {
	  event.preventDefault(); // prevent selection start (browser action)

	  thumb.classList.add('dragging');
	  let shiftX = event.clientX - thumb.getBoundingClientRect().left; //store current offset posiition 
	  // shiftY not needed, the thumb moves only horizontally

	  slider.addEventListener('mousemove', onMouseMove);
	  slider.addEventListener('mouseup', onMouseUp);
	//   slider.addEventListener('mouseleave',onMouseUp);
	  

	  function onMouseMove(event) {

		let newLeft = event.clientX /*- shiftX */- slider.getBoundingClientRect().left;

		// the pointer is out of slider => lock the thumb within the bounaries
		if (newLeft < 0) {
		  newLeft = 0;
		}
		let rightEdge = slider.offsetWidth;
		if (newLeft > rightEdge) {
		  newLeft = rightEdge;
		}

		thumb.style.left = newLeft + 'px';
	  }

	  function onMouseUp(event) {
		slider.removeEventListener('mouseup', onMouseUp);
		slider.removeEventListener('mousemove', onMouseMove);
		// slider.removeEventListener('mouseleave',onMouseUp);
		thumb.classList.remove('dragging');


		//send event current time changed
		// let pos = (slider.offsetWidth - thumb.offsetWidth+6);
		let pos = event.clientX  - slider.getBoundingClientRect().left   ;
		if(audioPlayer.duration && audioPlayer.getAttribute('data-id') == id ){
			let newTime = (pos +1 )/slider.clientWidth * audioPlayer.duration;
			if(newTime)
				audioPlayer.currentTime = newTime;
				monitorProgress();
		}

		//console.log('pos :' + pos);
	  }


	};

	thumb.ondragstart = function() {
	  return false;
	};
}

volumeScroller(){
	let audioPlayer = this.audioPlayer;

	let p = document.body.querySelector('#playermod_player');
	if(!p){
		return;
	}
	let clicked=false;

	let div = p.querySelector('.volume');
	let rail = div.querySelector('.rail');
	if(!div) return;

	let handle = div.querySelector('.handle');
	//set handle to curren volume 
	let volume = this.audioPlayer.volume;


	let style = window.getComputedStyle(div);
	let width=   rail.offsetWidth;

	// let marginLeft = parseInt(style.marginLeft.replace('px',''));
	handle.style.left = (volume * width)+'px';
	function clamp(left){
		if(left > width)
			left =width;
		else if (left < 0) 
			left = 0;

		return left;
	}
	rail.addEventListener('mousedown',(ev)=>{
		let leftEdge = div.getBoundingClientRect().left;
		ev.preventDefault();
		let left = clamp(ev.clientX - leftEdge);
		handle.style.left=left+'px';
		// console.log('mouse down :'+ left);
		// console.log(width);
		if(audioPlayer){
	
			// console.log(parseFloat(left/width));

			audioPlayer.volume=parseFloat(left/width);
		}
		clicked=true;
	});

	

	rail.addEventListener('mousemove',function(ev){
		let leftEdge = div.getBoundingClientRect().left;
		ev.preventDefault();
		// console.log('move ');
		let left =clamp(ev.clientX - leftEdge);
		// handle.style.left=left+'px';

		// console.log(ev);
	})

	function handleMove(ev){
		ev.preventDefault();
		let leftEdge = div.getBoundingClientRect().left;
		let left = clamp(ev.clientX - leftEdge);
		audioPlayer.volume=parseFloat(left/width);
		handle.style.left=left+'px';
	}

	// function handleMouseUp(ev){

	// }

	handle.addEventListener('mousedown',(ev)=>{
		// this.log('mousedown',7);
		document.addEventListener('mousemove', handleMove);
		let fun = (ev)=>{
			// this.log('mouseup',7);
			document.removeEventListener('mousemove',handleMove);
			document.removeEventListener('mouseup',fun);
		}
		
		document.addEventListener('mouseup',fun);
	
	});

	let volume_up = p.querySelector('.js-volume-up');
	let volume_down = p.querySelector('.js-volume-down');

	volume_up.addEventListener('click',(ev)=>{
		// console.log('up');
		let vol = audioPlayer.volume;
		vol +=0.1;

		if(vol>1) vol=1;
		audioPlayer.volume=vol;
		handle.style.left=vol*width+'px';
	});

	volume_down.addEventListener('click',(ev)=>{
		// console.log('down');
		let vol = audioPlayer.volume;
		vol -=0.1;

		if(vol<0) vol=0;
		audioPlayer.volume=vol;
		handle.style.left=vol*width+'px';
	})
}

playerAction(id,action=null){
	let audioPlayer=this.audioPlayer;

	if(!audioPlayer) {
		this.error('no audio player, cannot play');
		return false;
	} 

	let p = audioPlayer.parentElement;

	//extract title, src, image and other data from id or from api 
	let title = null;
	let src = null;
	let playelem = null;
	let globalPlayer = audioPlayer.parentElement;
	let data_id=audioPlayer.getAttribute('data-id');

	//api implementation 
	let resp = fetch(this.endpoint+'?id='+id.match(/\d/g).join('')).then(r=>r.json()).then(data=>{
		const img = globalPlayer.querySelector('.image img');
		const link = globalPlayer.querySelector('.link');
        const wave = globalPlayer.querySelector('.bar img');

		if(img && data.image){
			img.src=data.image;
			img.style.display='block';
		}else 
			console.log('no image');

		if(link && data.product_link){
			if(link.src){
				link.src=data.product_link;
			}else if(link.action)
				link.action=data.product_link;
			link.style.display='block';
		}

        if(wave && data.wave){
            console.log('updating wave');
            wave.src=data.wave;
        }
	})

	//detect action 
	if(!action || action=='auto'){
		// let = audioPlayer.paused?'play':'stop';
		action='play';
		if(data_id == id ){
			if(audioPlayer.src && audioPlayer.paused)
				action='resume';
			else
				action='stop';
		}
	}

	this.log('PM: player action ' + action + ' '+data_id + ' != '+ id,6);
	if(!id){
		this.error('PM: id of player is mandatory');
		return false;
	}

	audioPlayer.setAttribute('data-id',id);

	if(action == 'play' || action=='resume'){
		document.querySelectorAll('.mejs-pause').forEach(function(el){
			el.classList.remove('mejs-pause');
			el.classList.remove('active');
			 el.classList.add('mejs-play');
	
	 	}); // stop prev playing 
	}else if(action=='stop'){
		document.querySelectorAll('.mejs-play').forEach(function(el){
			el.classList.remove('mejs-play');
			el.classList.remove('active');
			//  el.classList.add('mejs-pause');
	 	});
	}

	
	if(typeof window[id] =='object' && window[id] ){
		let window_id = window[id];

		if(window[id].length==2) 
			window_id = window[id][1];

		playelem = window[id];
	
		// if(!playelem){
		// 	console.error('cannot find id: '+id);
		// }
		// console.log(playelem.length);
		// console.log(playelem);

		if(playelem.length > 1)
			playelem=playelem[0];

		let audio = playelem.querySelector('audio');

		title = audio.getAttribute('title');
		src = audio.getAttribute('src');

		// let elembtn = playelem.querySelector('.mejs-playpause-button');
		let elembtn = playelem.querySelector('.js-player-button');

		if(!elembtn){
			this.warn('js-player-button not found');
		}
		else if(action=='play' || action=='resume'){
			elembtn.classList.remove('mejs-play');
			elembtn.classList.add('mejs-pause');
			elembtn.classList.add('active');
		}else{
			elembtn.classList.remove('mejs-pause');
			elembtn.classList.remove('active');
			elembtn.classList.add('mejs-play');
		}
	}

	let playbtn = globalPlayer.querySelector('.play');
	let stopbtn = globalPlayer.querySelector('.pause');
	

	if(action=='play' || action=='resume'){
		playbtn.style.display='none';
		stopbtn.style.display='';
	}else{
		playbtn.style.display='';
		stopbtn.style.display='none';
	}

	if(action=='play' || action=='resume'){
		p.querySelector('.title').innerHTML=title;
		globalPlayer.querySelector('.pause').style.display='';
		globalPlayer.querySelector('.play').style.display='none';
		if(audioPlayer.src!=src && action!='resume'){
			//console.log("different" + src +' != ' + audioPlayer.src);
			audioPlayer.src=src;
		}

		audioPlayer.play().catch(e=>{
		
			console.error('Cannot play this src: '+audioPlayer.src + ' '+e.message);
			// console.log(e);
			// audioPlayer.pause(); //set icons etc
		});
		this.monitorProgress()
	}else{
		globalPlayer.querySelector('.play').style.display='';
		globalPlayer.querySelector('.pause').style.display='none';
		audioPlayer.pause();
	}

}
 
initPlayerLinks(wrapper){

	if(!wrapper)
		wrapper = document.body;

	wrapper.querySelectorAll('audio.playermod:not(.used)').forEach((audio,i)=>{
	   //add wrapper add player 
	   let parent = audio.parentNode;
	 

	   let template = document.getElementById('player_template');

	   if(!template) {
			this.warning('Cannot initialize players links. No player template found. exiting....');
			return ;
	   }
	  
	   let instance = document.importNode(template.content,true);
	
	   let isMediaelement = instance.querySelector('.mejs-container');
	   let playpause =null;
	// let id =null;

	   var id = null, socket=null;
	    //backward compatibility to mediaelement
		if(isMediaelement){
			instance.querySelector('.player').style.display='block'
			id='mep_'+audio.getAttribute('data-id');
			instance.querySelector('.mejs-container').id=id;
			socket = instance.querySelector('.mejs-mediaelement');
			socket.appendChild(audio);

	    	playpause = instance.querySelector('.mejs-playpause-button button');
	   		
		}else{
			id='js-player-id-'+audio.getAttribute('data-id');
			instance.querySelector('.js-player').id=id;
			playpause=instance.querySelector('.js-player-button');
			playpause.setAttribute('aria-label','Play/Pause');
			instance.querySelector('.js-player-title').innerHTML = audio.getAttribute('data-name');
			socket = instance.querySelector('.js-player-socket');
			audio.classList.add('used');
			socket.appendChild(audio);
		}

		this.log('init player  '+ i + ' on wrapper '+ wrapper.tagName,6);

		playpause.addEventListener('click',(e)=>{
			e.preventDefault();
			this.log('clicked '+id,7);
			this.playerAction(id,'auto');
		});


		/*
	   	playpause.addEventListener('clicksdfsdf',function(e){

		   e.preventDefault();
			let b = e.target;
			if(audioPlayer.getAttribute('data-id')==id) {
					//send event to global player 
				  if(!audioPlayer.paused){
						  if(verbose > 2 ) console.log('audio stopping the same '+ id +' '+ audioPlayer.getAttribute('data-id'));
					   audioPlayer.src='';
						  audioPlayer.removeAttribute('data-id');
					   audioPlayer.pause();
					   if(player){
						player.querySelector('.play').style.display='';
						player.querySelector('.pause').style.display='none';
					}
	
					wrapper.querySelectorAll('.mejs-pause').forEach(function(el){
						   el.classList.remove('mejs-pause');
						el.classList.add('mejs-play');
					});
					  //the same is clicked as playing one so pause all
					   return ;
				  }
			  }

			// function play id 
	
			

			audioPlayer.src=audio.src;
			// audioPlayer.volume=0.1;
			// audioPlayer.src='/mp3/some.mp3';
			audioPlayer.setAttribute('data-id',id);
	
			// if(verbose > 2 ) console.log('audio playing');

			b.parentElement.classList.remove('mejs-play');
			b.parentElement.classList.add('mejs-pause');

		    if(player){
				player.querySelector('.play').style.display='none';
				player.querySelector('.pause').style.display='';
				//replace title 
				player.querySelector('.title').innerHTML=socket.querySelector('audio').getAttribute('title');
			}

			audioPlayer.play().catch(e=>{
				console.error('Cannot play this src: '+audioPlayer.src + e.message);
				// console.log(e);
				// audioPlayer.pause(); //set icons etc
			});
			this.monitorProgress();

	    });*/

		parent.appendChild(instance);
		// console.log(parent.lastElementChild);
		this.thumb_scroller(parent.lastElementChild);
		

	});
}

//  zeroPad2 = (num, places) => String(num).padStart(places, '0');

 zeroPad(um, places){
	 return String(num).padStart(places, '0');
 }
 monitorProgress(){ //monito player progress, monitor widget 
	let audioPlayer=this.audioPlayer;

	let percent = audioPlayer.getAttribute('data-percent');
	let id = audioPlayer.getAttribute('data-id');
	let player = document.getElementById(id);
	let currentTime=audioPlayer.currentTime ;
	let Player = audioPlayer.parentNode;

	if(!player && audioPlayer.paused) { this.log('no player',7); clearTimeout(()=>this.monitorProgress()); return;}
	
	if(audioPlayer.paused){
		this.log('clear player progress monitor',4);
		clearTimeout(()=>this.monitorProgress());
	}else{
		setTimeout(()=>this.monitorProgress(),500);
	}

	if(currentTime > 0 ){
		let newPercent = ((audioPlayer.currentTime / audioPlayer.duration)*100.0);
		if(newPercent!=percent){
			audioPlayer.setAttribute('data-percent',newPercent);
			//update element percent 
	
			Player.querySelector('.progress').style.width = Player.querySelector('.bar').clientWidth * newPercent/100.0 +'px'

			if(player &&  player.querySelector('.mejs-time-total')){
				let total = player.querySelector('.mejs-time-total').clientWidth;
				let newWidth= parseInt(total * newPercent / 100.0);
				let handle = player.querySelector('.mejs-time-handle');
				if(!handle.classList.contains('dragging'))
					handle.style.left = (newWidth)+'px';
				player.querySelector('.mejs-time-current').style.width= newWidth+'px';
			}
		}

		if(player){
			let elemTime=player.querySelector('.mejs-currenttime');
			let elemDur=player.querySelector('.mejs-duration');
			if(elemTime)
			elemTime.innerHTML=zeroPad(parseInt(currentTime/60.0),2)+':'+zeroPad(parseInt(currentTime%60),2);
			if(elemDur)
			elemDur.innerHTML=zeroPad(parseInt(audioPlayer.duration/60),2) + ':'+zeroPad(parseInt(audioPlayer.duration%60),2);
		}

	}else{
		Player.querySelector('.progress').style.width=0;
		// if(elemTime.innerHTML==)
		// elemTime.innerHTML='.....';
		// elemDur.innerHTML='.....';
	}

	}
	
	warning(msg){
		console.warn('PLAYERMOD: '+msg);
	}

	

	init(){
	let player;
	let audioPlayer;


	this.on('initLinks',(wrapper)=>{
		this.log('event init links',5);
		// this.initPlayerLinks();
		
	});

	player = document.getElementById('playermod_player');
	
	if(player ){
		audioPlayer = player.querySelector('audio');
		player.style.display='block';

		player.querySelector('.play').addEventListener('click',(ev)=>{
			if(!audioPlayer.src) return ;
			audioPlayer.play();
			let el = ev.currentTarget;
			el.style.display='none';
			// this.log(el,5);
			// console.log('play');
			// debugger;
			el.nextElementSibling.style.display='';

			document.body.querySelectorAll('.mejs-play.active').forEach((el)=>{
				el.classList.remove('mejs-play');
			 	el.classList.add('mejs-pause');
			});
			this.monitorProgress();
			
		});

		player.querySelector('.pause').addEventListener('click',(ev)=>{
			audioPlayer.pause();
			let el = ev.currentTarget;
			el.style.display='none';
			el.previousElementSibling.style.display='';

			document.body.querySelectorAll('.mejs-pause.active').forEach(function(el){
				el.classList.remove('mejs-pause');
			 	el.classList.add('mejs-play');
		 });

		});

		player.querySelector('.close').addEventListener('click',(ev)=>{
			let el = ev.currentTarget;
			let p = document.getElementById('playermod_player');
			// if(player.classList.contains('open'))
			p.classList.toggle('open');
		});

		player.querySelector('.bar img').addEventListener('click',(ev)=>{
			let el = ev.currentTarget;
			ev.preventDefault();
			ev.stopPropagation();

			//console.log( el.getBoundingClientRect().left );
			let pos = ev.clientX  - el.getBoundingClientRect().left   ;
			el.parentNode.querySelector('.progress').style.width=pos + 'px';
			if(audioPlayer.duration){
				let newTime = (pos +1 )/el.clientWidth* audioPlayer.duration;
				if(newTime)
					audioPlayer.currentTime = newTime;
			}
		});

	}else 
		audioPlayer = document.createElement('audio');

	$(audioPlayer).on("canplaythrough",()=>{
		this.log('I think I can play through the entire ',6);
	});

	audioPlayer.addEventListener('ended',()=>{
		this.playerAction(audioPlayer.getAttribute('data-id'),'stop');
		this.log('player track ended',6);
	});	

	$(audioPlayer).on("loadedmetadata", ()=>{
	
		let id = audioPlayer.getAttribute('data-id');
		let p = document.getElementById(id);
		document.getElementById('playermod_player').classList.add('open');
	
		//mejs-duration js-duration
		let duration = p.querySelector('.mejs-duration');
		if(duration) duration.innerHTML=zeroPad(parseInt(audioPlayer.duration/60),2) + ':'+zeroPad(parseInt(audioPlayer.duration%60),2);
	});
	
	//window.playermod.audio=audioPlayer;
	audioPlayer.volume=1;

	this.player=player;
	this.audioPlayer=audioPlayer;
	


	this.initPlayerLinks();
	this.volumeScroller();
	// window.onpopstate = f

}

}

