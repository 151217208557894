export default class Prestalib{
    // eventParams;

    constructor(){
        this.eventParams={
            detail:{},
            bubbles: true,
            cancelable: true,
            composed: false
        }
    }

    event(name,detail){
        let params = this.eventParams;
        if(detail)  params.detail=detail;
        let ev  = new CustomEvent(name,params)
        document.dispatchEvent(ev);
    }


    on(name,fn){
        //if(standard prestashop 1.7 event ) and name is handled add here 
        //else normal event
          //evnets updateCart, 
        //updateProduct
        document.addEventListener(name,fn);
    }
    
    onChangeElement(targetNode, cb){
       const config = { attributes: true, childList: false, subtree: false };
       const callback = function(mutationsList, observer) {
           console.log('PL '+callback);
           cb();
       };
       const observer = new MutationObserver(callback);
       observer.observe(targetNode, config);
   }
   

   observe(el,cb){
     
        const targetNode =el;
        const config = { attributes: true, childList: true, subtree: true };
        const callback = function(mutationsList, observer) {
            // Use traditional 'for loops' for IE 11
            for(const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    // console.log('A child node has been added or removed.');
                    cb();
                }
                else if (mutation.type === 'attributes') {
                    // console.log('The ' + mutation.attributeName + ' attribute was modified.');
                }
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
   }

    addElementEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on'+type, handler); else el.addEventListener(type, handler);
    }
    
    removeElementEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on'+type, handler); else el.removeEventListener(type, handler);
    }

  error(msg){
    window.console.error(this._getClassname()+ ': '+msg);
  }

  log(msg,level=4){
    if(this.verbose > level ) window.console.log(this._getClassname()+': '+msg);
  }

  warn(msg){
      this.warning(msg);
  }
  warning(msg){
    console.warn(this._getClassname() + ': '+ msg);
}
_getClassname(){
    return Object.getPrototypeOf(this).constructor.name;
}

setCookie(cookieName,cookieValue,nDays) {
    var today = new Date();
    var expire = new Date();
    if (nDays===null || nDays===0) nDays=1;
    expire.setTime(today.getTime() + 3600000*24*nDays);
    document.cookie = cookieName+"="+encodeURI(cookieValue) + ";expires="+expire.toGMTString()+";path=/";
}

getCookieValue(key)
{
    //=document.cookie.match(new RegExp('(^| )currency=([^;]+)')); if(sign) sign=sign[2];
    let currentcookie = document.cookie;
    if (currentcookie.length > 0)
    {
        let firstidx = currentcookie.indexOf(key + "=");
        if (firstidx != -1)
        {
            firstidx = firstidx + key.length + 1;
            let lastidx = currentcookie.indexOf(";",firstidx);
            if (lastidx == -1)
            {
                lastidx = currentcookie.length;
            }
            return decodeURI(currentcookie.substring(firstidx, lastidx));
        }
    }
    return "";
}

}


