import PlayerMod from "./playermod";


document.addEventListener('DOMContentLoaded', function () {
	let config = window.playermod;
	
	console.log('PM: boot');
	let p = new PlayerMod();

	if(typeof config=='object'){
		for(let k of Object.keys(config))
			p.config[k]=config[k];
	}

	window.playermod=p;
	// delete window.playermod_config;

});

// export default PlayerMod;